'use client';

import { useEffect } from 'react';

import { ArrowLeftCircleIcon, PlusCircleIcon } from '@heroicons/react/16/solid';
import { HomeIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';

import isdLogoWide from '@public/logo-wide.png';

import { useAuthContext } from '@/lib/context/AuthContext';

import { logAnalyticsEvent } from '@/lib/firebase/analytics';

import { reactAppJobCreateRoute, reactAppUrl } from '@/lib/env';

export default function Header({ className }: { className?: string }) {
  const { userDoc, isImpersonating, isAuthenticated } = useAuthContext();

  useEffect(() => {
    if (isAuthenticated) {
      logAnalyticsEvent('web_openDispatchScheduler');
    }
  }, [isAuthenticated]);

  return (
    <nav
      className={clsx(
        'app-header navbar min-h-[2.5rem] bg-primary px-2 text-base-100 md:px-12',
        className
      )}
    >
      <div className="navbar-start">
        <Link href={'/'} className="relative my-auto font-bold">
          <Image
            src={isdLogoWide}
            height={25}
            alt={'logo'}
            className="desktop-header-logo hidden md:block"
          />
          <Image
            src={isdLogoWide}
            height={20}
            alt={'logo'}
            className="mobile-header-logo md:hidden"
          />
          {!!userDoc && isImpersonating && (
            <div className="absolute right-0 top-[21px] text-[0.5rem]">{`Impersonating ${userDoc.get('nickname')}`}</div>
          )}
        </Link>
      </div>

      <div className="navbar-end gap-2">
        <CreateJobLinkButton />
        <DashboardLinkButton />
      </div>
    </nav>
  );
}

function DashboardLinkButton() {
  const onReturnToISD = () => {
    // Navigate to dashboard in same tab
    window.location.href = reactAppUrl;
  };
  return (
    <div
      className="btn flex h-auto min-h-0 items-center rounded-full bg-base-100 px-2 py-0.5 text-sm font-bold text-primary"
      onClick={onReturnToISD}
    >
      <ArrowLeftCircleIcon className="size-4 md:mr-2 md:size-6" />
      <div className="flex items-center gap-1 text-xs md:hidden">
        {'Dashboard '}
      </div>
      <div className="hidden items-center gap-1 md:flex">
        {'Return to Dashboard '}
        <HomeIcon className="size-4" />
      </div>
    </div>
  );
}

function CreateJobLinkButton() {
  const onClickNewJob = () => {
    const addJobUrl = reactAppUrl + reactAppJobCreateRoute;
    window.open(addJobUrl);
  };

  return (
    <div
      className="btn flex h-auto min-h-0 items-center rounded-full bg-base-100 px-2 py-0.5 text-xs font-bold text-primary md:text-sm"
      onClick={onClickNewJob}
    >
      <PlusCircleIcon className="size-4 md:mr-1 md:size-6" />
      <div className="flex items-center gap-1  md:mr-1">{'New Job'}</div>
    </div>
  );
}
